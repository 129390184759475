.table {
	table-layout: fixed;
}
.table .tableHead {
	user-select: none;
}
.table .tableCell.resizable {
	vertical-align: middle;
	text-align: center;

	padding: 5px 10px 5px 10px;
}
.table .tableCell.emptyCell {
	width: "auto";
}
.table .resizableTableCell {
	overflow: hidden;
	text-overflow: ellipsis;
}

.table .resizeLine {
	cursor: col-resize;
	width: 12px;
	height: 100%;
	touch-action: none;
	color: #515151;
	top: 0;
	right: 0px;
	display: flex;
	z-index: 100;
	position: absolute;
	flex-direction: column;
	justify-content: center;
	text-align: right;

	opacity: 0.3;
	transition: opacity 0.1s;
}
.table .resizeLine:hover {
	opacity: 1;
}
