.QRCodeContainer {
	overflow: hidden;
	padding: 10px;
	text-align: center;
}

/* On fait en sorte que le QRCode puisse toujours être affiché en entier, peu importe la résolution de l'écran */
/* Inspiré de : https://stackoverflow.com/a/69227493/5077092 */
.QRCode {
	--dialog-header-height: 62px;
	--dialog-padding: 10px * 2;
	/* On calcul la taille minimum en prennant en compte la taille de l'écran et la place disponible */
	--h: min(calc(100vw - var(--dialog-padding)), calc(100vh - calc(var(--dialog-header-height) + var(--dialog-padding))));
	height: var(--h);
	width: var(--h);
}
